define("frontend/components/service-category", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    media: inject.service(),
    serviceCategory: null,
    expanded: computed.alias('serviceCategory.expanded'),
    collapsed: computed.not('expanded'),
    dragAndDropActivated: false,
    showAddCategory: false,
    isPetParent: false,
    selectedServiceCategory: computed('serviceCategory.name', function () {
      return this.get('serviceCategory.name');
    }),
    expandedObserver: observer('serviceCategory.expanded', function () {
      if (this.get('serviceCategory.expanded')) {
        this.set('selectedServiceCategory', this.get('serviceCategory.name'));
      }
    }),
    willInsertElement: function willInsertElement() {
      if (this.get('media.isMobile') && !this.get('isPetParent')) {
        this.set('serviceCategory.expanded', false);
      }
    },
    evenChild: computed('categoryNum', function () {
      return this.get('categoryNum') % 2 == 0;
    }),
    halfWidth: computed('categoryNum', 'totalCategories', 'showAddCategory', 'isPetParent', function () {
      if (this.get('totalCategories') % 2 == 0) {
        return true;
      } else {
        if (this.get('isPetParent')) {
          return this.get('categoryNum') != this.get('totalCategories') - 1;
        } else {
          if (this.get('totalCategories') != 8) {
            return true;
          } else {
            return this.get('categoryNum') != this.get('totalCategories') - 1;
          }
        }
      }
    }),
    nonAddOnServices: computed('services.@each.{is_add_on}', function () {
      return this.get('services').filter(function (service) {
        return !service.get('is_add_on');
      });
    }),
    addOnServices: computed('services.@each.{is_add_on}', function () {
      return this.get('services').filter(function (service) {
        return service.get('is_add_on');
      });
    }),
    actions: {
      createOfferedService: function createOfferedService() {
        this.get('createOfferedService')(this.get('serviceCategory'));
      },
      toggleCollapse: function toggleCollapse(hasUnsignedAgreements) {
        if (hasUnsignedAgreements) {
          this.get('showSignContractsModal')(this.get('serviceCategory.id'));
        } else {
          this.toggleProperty('collapsed');
          this.set('dragAndDropActivated', false);

          if (!this.get('media.isMobile') && !this.get('isPetParent')) {
            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/service_categories/").concat(this.get('serviceCategory.id'), "/set_expanded"),
              method: 'POST',
              data: {
                expanded: !this.get('collapsed')
              }
            });
          }
        }
      },
      changeServiceCategory: function changeServiceCategory(categoryName) {
        this.toggleProperty('serviceCategory.expanded');
        this.get('selectNewServiceCategory')(categoryName);
      },
      clickDragAndDrop: function clickDragAndDrop() {
        var _this = this;

        this.toggleProperty('dragAndDropActivated');
        this.set('showDragAndDropFlashNote', true);
        Ember.run.later(function () {
          _this.set('showDragAndDropFlashNote', false);
        }, 2000);
      },
      clickOfferedService: function clickOfferedService(offeredService) {
        if (this.get('isPetParent') && this.get('serviceCategory.hasAddOnServices')) {
          this.set('selectedOfferedService', offeredService);
        }

        this.get('clickOfferedService')(offeredService);
        return false;
      },
      clickOfferedServiceAddOn: function clickOfferedServiceAddOn(addOnService) {
        if (this.get('clickOfferedServiceAddOn')) {
          this.get('clickOfferedServiceAddOn')(addOnService);
        }
      },
      dragStart: function dragStart() {
        var _this2 = this;

        // The run later is in case you're trying to drag a service blocked by the scroll area
        Ember.run.later(function () {
          _this2.set('dragScrollActivated', true);
        }, 0);
      },
      dragEnd: function dragEnd() {
        this.set('dragScrollActivated', false);
      },
      repositionServices: function repositionServices() {
        var sorted_offered_service_ids = this.get('nonAddOnServices').mapBy('id').filter(function (id) {
          return id != null;
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/reposition"),
          method: 'PUT',
          data: {
            sorted_offered_service_ids: sorted_offered_service_ids
          }
        });
      },
      repositionAddOnServices: function repositionAddOnServices() {
        var sorted_offered_service_ids = this.get('addOnServices').mapBy('id').filter(function (id) {
          return id != null;
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/reposition"),
          method: 'PUT',
          data: {
            sorted_offered_service_ids: sorted_offered_service_ids
          }
        });
      },
      redirectToSettings: function redirectToSettings() {
        this.get('redirectToSettings')(this.get('serviceCategory.id'));
      }
    }
  });

  _exports.default = _default;
});