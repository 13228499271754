define("frontend/routes/edit-recurring-appointment-group", ["exports", "frontend/routes/base-route", "moment", "frontend/mixins/transition-back-route"], function (_exports, _baseRoute, _moment, _transitionBackRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    errorHandler: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    model: function model(params) {
      this._super.apply(this, arguments);

      var recurringAppointmentGroup = this.store.findRecord('recurring-appointment-group', params.id, {
        reload: true
      });
      var offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      var careProviders = this.store.findAll('care-provider');
      var serviceCategories = this.store.query('service-category', {}, {
        reload: true
      });
      return Ember.RSVP.hash({
        recurringAppointmentGroup: recurringAppointmentGroup,
        offeredServices: offeredServices,
        careProviders: careProviders,
        serviceCategories: serviceCategories
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('redirecting', false);
      controller.set('saving', false);
    },
    firstWeekdayFromDate: function firstWeekdayFromDate(dayOfWeek, date, repeatsBiweekly) {
      var momentDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      var daysDiff = dayOfWeek - momentDate.day();

      if (daysDiff < 0 && !repeatsBiweekly) {
        daysDiff += 7;
      }

      var weekDay = momentDate.add(daysDiff, 'days');
      var SUNDAY = 0;

      if (weekDay.isBefore((0, _moment.default)().startOf('day')) && repeatsBiweekly) {
        if (dayOfWeek === SUNDAY) {
          weekDay.add(1, 'week');
        } else {
          weekDay.add(2, 'weeks');
        }
      }

      return weekDay.format('YYYY-MM-DD');
    },
    actions: {
      cancel: function cancel(recurringAppointmentGroup) {
        var indexController = Ember.getOwner(this).lookup('controller:edit-recurring-appointment-group.index');
        var deletedRecurringAppointments = indexController.get('deletedRecurringAppointments');
        var recurringAppointments = recurringAppointmentGroup.get('recurring_appointments');
        recurringAppointments.pushObjects(deletedRecurringAppointments);
        recurringAppointments.forEach(function (ra) {
          return ra.rollbackAttributes();
        });
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId).then(function () {
          return window.location.reload();
        });
      },
      remove: function remove(recurringAppointmentGroup, backHouseholdId) {
        var queryParams = {
          backRoute: 'edit-recurring-appointment-group.index',
          backHouseholdId: backHouseholdId
        };
        return this.transitionTo('remove-recurring-appointment-group', recurringAppointmentGroup.get('id'), {
          queryParams: queryParams
        });
      },
      save: function save(recurringAppointmentGroup) {
        var _this = this;

        var indexController = Ember.getOwner(this).lookup('controller:edit-recurring-appointment-group.index');

        if (this.get('controller.saving')) {
          return false;
        }

        var changesStartOn = indexController.get('startDate');
        var frequency = indexController.get('selectedRepeatOption');
        var end_date = indexController.get('selectedEndDateOption') === 'never' ? 'never' : indexController.get('endDate');
        var made_changes = indexController.get('madeChanges');
        recurringAppointmentGroup.set('changes_start_on', changesStartOn);
        recurringAppointmentGroup.set('end_date', end_date);
        recurringAppointmentGroup.set('made_changes', made_changes);
        this.set('controller.saving', true);
        recurringAppointmentGroup.get('recurring_appointments').forEach(function (recurringAppointment) {
          var startDate = recurringAppointment.get('start_date');
          var dayOfWeek = (0, _moment.default)(startDate, 'YYYY-MM-DD').day();

          if (!recurringAppointment.get('isDeleted')) {
            if (changesStartOn) {
              var newStartDate = _this.firstWeekdayFromDate(dayOfWeek, changesStartOn, frequency === 'biweekly');

              recurringAppointment.set('start_date', newStartDate);
            }

            recurringAppointment.set('end_date', end_date);
            recurringAppointment.set('frequency', frequency);
            var addOnServiceIds = recurringAppointment.get('recurring_appointment_add_on_services').map(function (raaos) {
              return raaos.get('offered_service.id');
            });
            recurringAppointment.set('add_on_service_ids', addOnServiceIds);
          }
        });
        recurringAppointmentGroup.save().then(function () {
          var backRoute = _this.get('controller.backRoute');

          var backHouseholdId = _this.get('controller.backHouseholdId');

          var backId = _this.get('controller.backId');

          if (backRoute == 'companies.remove-staff') {
            window.location.href = "companies/".concat(backHouseholdId, "/remove-staff/").concat(backId);
          } else if (backRoute == 'companies.remove-service') {
            window.location.href = "companies/".concat(backHouseholdId, "/remove-service/").concat(backId);
          } else {
            window.location.href = "client-list/".concat(backHouseholdId, "/appointments?appointmentType=repeating");
          }
        }).catch(function (error) {
          var _error$errors$0$detai, _error$errors, _error$errors$, _error$errors$$detail;

          _this.set('controller.saving', false);

          var unknownError = "An unknown error occurred";
          var errorMessage = (_error$errors$0$detai = error === null || error === void 0 ? void 0 : (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : (_error$errors$ = _error$errors[0]) === null || _error$errors$ === void 0 ? void 0 : (_error$errors$$detail = _error$errors$.detail) === null || _error$errors$$detail === void 0 ? void 0 : _error$errors$$detail.detail) !== null && _error$errors$0$detai !== void 0 ? _error$errors$0$detai : unknownError;

          _this.get('flashMessage').sendFlashMessage(errorMessage, 'user-error');
        });
      }
    }
  });

  _exports.default = _default;
});