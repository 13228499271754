define("frontend/templates/components/ppb-percentage-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pVX7W9yR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"showError\"]],\"input-error\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"percentage-sign \",[28,\"if\",[[24,[\"floatLabel\"]],\"percentage-sign-visible\"],null]]]],[8],[0,\"%\"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ppb-input-label \",[28,\"if\",[[24,[\"floatLabel\"]],\"floating-label\"],null],\" \",[28,\"if\",[[24,[\"showError\"]],\"label-error\"],null]]]],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"floatLabel\"]],\"floated-input\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"type\",\"placeholder\",\"focusIn\",\"focusOut\",\"disabled\"],[[24,[\"maskedValue\"]],\"number\",[24,[\"placeholderText\"]],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[24,[\"disabled\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"hr\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"input-bottom-border\"],[8],[9],[0,\"\\n  \"],[7,\"hr\",true],[10,\"aria-hidden\",\"true\"],[11,\"class\",[29,[\"input-bottom-border-blue \",[28,\"if\",[[24,[\"focused\"]],\"bottom-border-focused\"],null],\" \",[28,\"if\",[[24,[\"showError\"]],\"bottom-border-error\"],null]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"\\n      \"],[1,[22,\"errorMessage\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/ppb-percentage-input.hbs"
    }
  });

  _exports.default = _default;
});