define("frontend/models/household-user", ["exports", "ember-data", "ember-cp-validations", "frontend/utils/phone-number-type", "frontend/utils/invoice-frequency-options"], function (_exports, _emberData, _emberCpValidations, _phoneNumberType, _invoiceFrequencyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var USER_PLACEHOLDER = "assets/images/staff/placeholder-human-image@3x.png";
  var Validations = (0, _emberCpValidations.buildValidations)({
    "user.first_name": (0, _emberCpValidations.validator)("presence", true),
    "user.last_name": (0, _emberCpValidations.validator)("presence", true),
    emails: (0, _emberCpValidations.validator)("presence", true),
    addresses: (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: computed.not("model.isHouseholdOwner")
    })
  });

  var _default = _emberData.default.Model.extend(Validations, {
    modelName: "household-user",
    household: _emberData.default.belongsTo("household", {
      async: true
    }),
    company_user_profile: _emberData.default.belongsTo("company-user-profile", {
      async: false
    }),
    user: _emberData.default.belongsTo("user"),
    country: _emberData.default.attr("string"),
    phone_numbers: _emberData.default.hasMany("phone_number", {
      async: true
    }),
    emails: _emberData.default.hasMany("email", {
      async: true
    }),
    addresses: _emberData.default.hasMany("address", {
      async: true
    }),
    auto_pay: _emberData.default.attr("boolean"),
    auto_pay_tip_percentage: _emberData.default.attr("number"),
    invoice_frequency: _emberData.default.attr("string"),
    company_id: _emberData.default.attr(),
    sales_tax_policy_id: _emberData.default.attr(),
    avatar_url: computed.alias("user.avatar_url"),
    first_name: computed.alias("user.first_name"),
    getFirstName: computed.alias("user.getFirstName"),
    preferred_name: computed.alias("user.preferred_name"),
    last_name: computed.alias("user.last_name"),
    dob: computed.alias("user.dob"),
    gender: computed.alias("user.gender"),
    email: computed.alias("user.email"),
    fullName: computed.readOnly("user.fullName"),
    emd5: computed.readOnly("user.emd5"),
    sendBillsNever: computed.equal("invoice_frequency", _invoiceFrequencyOptions.default.NEVER),
    sendBillsWeekly: computed.equal("invoice_frequency", _invoiceFrequencyOptions.default.WEEKLY),
    sendBillsMonthly: computed.equal("invoice_frequency", _invoiceFrequencyOptions.default.MONTHLY),
    sendBillsBiweekly: computed.equal("invoice_frequency", _invoiceFrequencyOptions.default.BIWEEKLY),
    sendBillsSemimonthly: computed.equal("invoice_frequency", _invoiceFrequencyOptions.default.SEMIMONTHLY),
    role: computed("isHouseholdOwner", function () {
      return this.get("isHouseholdOwner") ? "Primary Contact" : "Secondary Contact";
    }),
    avatarOrPlaceholder: computed("avatar_url", function () {
      return this.get("avatar_url") || USER_PLACEHOLDER;
    }),
    isHouseholdOwner: computed("household", "household.owner.id", function () {
      return this.get("household.id") == null || this.get("household.owner.id") === this.get("id");
    }),
    primaryAddress: computed.readOnly("addresses.firstObject"),
    mobilePhones: computed("phone_numbers.@each.phone_type_id", function () {
      return this.get("phone_numbers").filterBy("phone_type_id", _phoneNumberType.default.MOBILE);
    }),
    presentAddress: computed.notEmpty("addresses"),
    presentPhone: computed.notEmpty("phone_numbers"),
    presentMobilePhone: computed.notEmpty("mobilePhones"),
    presentEmail: computed.notEmpty("emails"),
    countryInfo: inject.service(),
    states: computed("country", "countryInfo.caProvinces.[]", "countryInfo.usStates.[]", function () {
      if (this.get("country") === "Canada") {
        return this.get("countryInfo.caProvinces");
      } else {
        return this.get("countryInfo.usStates");
      }
    })
  });

  _exports.default = _default;
});