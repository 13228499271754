define("frontend/models/invoice-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    appointments: _emberData.default.hasMany('appointment', {
      async: true
    }),
    grouped_appointments: _emberData.default.hasMany('grouped-appointment', {
      async: true
    }),
    invoice_adjustments: _emberData.default.hasMany('invoice-adjustment', {
      async: false
    }),
    reminder_last_sent_date: _emberData.default.attr('date'),
    last_sent_date: _emberData.default.attr('date'),
    total_credit: _emberData.default.attr('number'),
    hasSalesTax: computed.gt('salesTaxTotal', 0),
    servicesTotals: Ember.computed("appointments.[]", function () {
      var appointments = this.get("appointments");
      var taxable = 0;
      var nonTaxable = 0;
      var combined = 0;
      appointments.forEach(function (appointment) {
        var coreServiceIsTaxable = appointment.get('offered_service.apply_sales_tax');
        var appointmentCost = appointment.get("cost");

        if (coreServiceIsTaxable) {
          taxable += appointmentCost;
        } else {
          nonTaxable += appointmentCost;
        }

        combined += appointmentCost;
        appointment.appointment_add_on_services.forEach(function (addOnService) {
          var addOnServiceIsTaxable = addOnService.get('offered_service.apply_sales_tax');
          var addOnCost = addOnService.get('cost');

          if (addOnServiceIsTaxable) {
            taxable += addOnCost;
          } else {
            nonTaxable += addOnCost;
          }

          combined += addOnCost;
        });
      });
      return {
        taxable: taxable,
        nonTaxable: nonTaxable,
        combined: combined
      };
    }),
    adjustmentsSubtotals: computed('invoice_adjustments.@each.amount', function () {
      var combined = 0;
      var taxable = 0;
      this.get('invoice_adjustments').forEach(function (a) {
        if (a.get('is_sales_tax')) return;

        if (a.get('apply_sales_tax') || a.get('isDiscount')) {
          taxable += a.get('amount');
        }

        combined += a.get('amount');
      });
      return {
        taxable: taxable,
        combined: combined
      };
    }),
    subtotals: computed('adjustmentsSubtotals', 'servicesTotals', function () {
      var taxable = this.get('adjustmentsSubtotals.taxable') + this.get('servicesTotals.taxable');
      var combined = this.get('adjustmentsSubtotals.combined') + this.get('servicesTotals.combined');
      return {
        taxable: taxable,
        combined: combined
      };
    }),
    salesTaxTotal: computed('invoice_adjustments.@each.{amount,is_sales_tax}', function () {
      return this.get('invoice_adjustments').filterBy('is_sales_tax', true).get('firstObject.amount') || 0;
    }),
    salesTaxRate: computed('invoice_adjustments.@each.{amount,is_sales_tax}', function () {
      return this.get('invoice_adjustments').filterBy('is_sales_tax', true).get('firstObject.sales_tax_rate') || 0;
    }),
    total: computed('subtotals', 'salesTaxTotal', function () {
      return this.get('subtotals.combined') + this.get('salesTaxTotal');
    })
  });

  _exports.default = _default;
});