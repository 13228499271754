define("frontend/components/override-sales-tax-policy-default-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,
    oldDefault: Ember.computed('originalDefaultSalesTaxPolicy', function () {
      return "".concat(this.get('originalDefaultSalesTaxPolicy.rate'), "% (").concat(this.get('originalDefaultSalesTaxPolicy.name'), ")");
    }),
    newDefault: Ember.computed('company.sales_tax_policies', function () {
      var newDefault = this.get('company.sales_tax_policies').find(function (policy) {
        return policy.get('is_default') && !policy.get('isDeleted');
      });
      return newDefault;
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.get('company').set('update_all_households_default_sales_tax', option);
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});