define("frontend/components/ppb-percentage-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ppb-money-input'],
    maskedValue: null,
    formatValue: function formatValue(value) {
      var parsedValue = parseFloat(value);

      if (parsedValue || parsedValue == 0) {
        return parsedValue;
      } else {
        return null;
      }
    },
    valueObserver: Ember.observer('maskedValue', function () {
      var maskedValue = parseFloat(this.get('maskedValue'));
      var value = parseFloat(this.get('value'));

      if (isNaN(maskedValue)) {
        this.set('value', null);
      } else if (maskedValue !== value) {
        this.set('value', maskedValue);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('value') || this.get('value') == 0) {
        this.set('floatLabel', true);
      }

      if (!this.get('focused')) {
        this.set('maskedValue', this.formatValue(this.get('value')));
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get('placeholder')) {
        this.set('floatLabel', true);
        this.set('placeholderText', this.get('placeholder'));
      }

      this.set('maskedValue', this.formatValue(this.get('maskedValue')));
    },
    actions: {
      focusIn: function focusIn() {
        this.set('focused', true);
        this.set('floatLabel', true);
        this.set('placeholderText', '');
      },
      focusOut: function focusOut() {
        if ((this.get('maskedValue') == null || this.get('maskedValue') == '') && !this.get('placeholder')) {
          this.set('floatLabel', false);
        }

        this.set('focused', false);
        this.set('placeholderText', this.get('placeholder'));
        this.set('maskedValue', this.formatValue(this.get('maskedValue')));
      }
    }
  });

  _exports.default = _default;
});