define("frontend/serializers/company", ["exports", "ember-data", "active-model-adapter", "frontend/mixins/country-serializer"], function (_exports, _emberData, _activeModelAdapter, _countrySerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _countrySerializer.default, {
    attrs: {
      invoice_frequencies: {
        serialize: 'records',
        deserialize: 'ids'
      },
      default_invoice_frequency: {
        serialize: 'ids',
        deserialize: 'ids'
      },
      sales_tax_policies: {
        serialize: 'records',
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});