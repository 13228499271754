define("frontend/controllers/companies/getting-paid", ["exports", "frontend/config/environment", "ember-data", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberData, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'companyTaxId': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    }), (0, _emberCpValidations.validator)('tax-id'), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'company.bank_account_nickname': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    })],
    'bankUser.last_four_ssn': [(0, _emberCpValidations.validator)('last-four-ssn')],
    'bankUser.dob': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: false,
      presence: true
    }), (0, _emberCpValidations.validator)('date')],
    'company.sales_tax': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 50,
      gte: 1,
      allowBlank: true,
      positive: true,
      message: 'This must be greater than or equal to 1'
    })]
  }); // This is for payment reminder, since it
  // has to change when invoice frequency changes

  var REMINDER_OPTIONS = {
    monthly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'every-2-weeks',
      label: 'Every 2 weeks'
    }, {
      value: 'off',
      label: 'Never'
    }],
    biweekly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'off',
      label: 'Never'
    }],
    weekly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'off',
      label: 'Never'
    }],
    semimonthly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'off',
      label: 'Never'
    }],
    daily: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'off',
      label: 'Never'
    }],
    null: []
  };

  var _default = Ember.Controller.extend(Validations, {
    flashMessage: inject.service(),
    selectedSalesTaxPolicy: null,
    selectedToRemoveSalesTaxPolicy: null,
    needSalesTaxPolicyDefaultAnswer: false,
    ePaymentOptions: computed('model.company.allows_ach_transfer', function () {
      var options = [{
        label: 'Credit/debit cards',
        value: 'cards'
      }];

      if (this.get('model.company.allows_ach_transfer')) {
        if (this.get('model.company.epayments') == 'ach') {
          options.push({
            label: 'ACH bank transfers',
            value: 'ach'
          });
        }

        options.push({
          label: 'Card and ACH',
          value: 'both'
        });
      }

      if (!this.get('model.company.has_stripe_account')) {
        options.push({
          label: 'None',
          value: 'none'
        });
      }

      return options;
    }),
    company: computed.alias('model.company'),
    canSave: computed('validations.attrs.bankUser.dob.isValid', 'validations.attrs.bankUser.last_four_ssn.isValid', 'validations.attrs.company.bank_account_nickname.isValid', 'validations.attrs.companyTaxId.isValid', 'validations.attrs.company.sales_tax.isValid', 'company.tax_id', 'company.acceptsEpayments', function () {
      var accepts_payments = this.get('company.acceptsEpayments');
      return this.get('validations.attrs.company.sales_tax.isValid') && (!accepts_payments || accepts_payments && this.get('validations.attrs.bankUser.dob.isValid') && this.get('validations.attrs.bankUser.last_four_ssn.isValid') && this.get('validations.attrs.company.bank_account_nickname.isValid') && this.get('validations.attrs.company.sales_tax.isValid') && (this.get('company.tax_id') || this.get('validations.attrs.companyTaxId.isValid')));
    }),
    bankUser: computed('company', function () {
      var promise = this.get('store').find('user', this.get('company.bank_user_id'));
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    companyTaxId: computed('company', function () {
      return this.get('company.tax_id');
    }),
    showLastFourSsn: computed('bankUser.last_four_ssn', function () {
      return this.get('bankUser.last_four_ssn') == null;
    }),
    showUserDob: computed('bankUser.dob', function () {
      return this.get('bankUser.dob') == null;
    }),
    autoPayOptions: [{
      value: 'opt_in',
      label: 'Allow clients to opt-in'
    }, {
      value: 'required',
      label: 'Required for all clients'
    }],
    serviceFeeOptions: [{
      value: true,
      label: 'Enabled'
    }, {
      value: false,
      label: 'Disabled'
    }],
    showConfirmNeverAutoBill: false,
    showConfirmSwitchUserInvoiceFrequencies: false,
    showChooseBankAccountLinkingMethod: false,
    editingBankAccount: false,
    editingAutoBill: false,
    editingBillReminders: false,
    anySidePanelActive: computed.or('editingBankAccount', 'editingAutoBill', 'editingBillReminders'),
    hoverBankAccount: false,
    changedTaxId: false,
    showBankAccount: computed('company.bank_account_number', function () {
      return this.get('company.bank_account_number');
    }),
    billReminderFrequencyOptions: computed('company.invoice_frequency', function () {
      return REMINDER_OPTIONS[this.get('company.invoice_frequency')];
    }),
    authorizedIndividuals: computed(function () {
      var promise = this.get('store').findAll('employee').then(function (employees) {
        return employees.filterBy('user.user_role', 'Owner').map(function (employee) {
          return {
            value: employee.get('user.id'),
            label: employee.get('user.fullName')
          };
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    removedInvoiceFrequencies: computed('company.invoice_frequencies.[]', function () {
      var _this = this;

      var removedFrequencies = [];
      var oldFrequencies = this.get('originalInvoiceFrequencies');
      var newFrequencies = this.get('company.invoice_frequencies');
      oldFrequencies.forEach(function (frequency) {
        if (!newFrequencies.includes(frequency)) {
          removedFrequencies.push(frequency);
        }
      });
      var removedFrequencyNames = removedFrequencies.map(function (rif) {
        var frequency = _this.get('model.invoiceFrequencies').filter(function (f) {
          return f.id == rif.id;
        });

        return frequency.get('firstObject.name');
      });
      return removedFrequencyNames;
    }),
    companyAccountVerified: computed('company.stripe_account_verified', function () {
      return this.get('company.stripe_account_verified') === 'verified';
    }),
    // Don't show the checks payable field to MixyPaws
    // TODO: switch this to not show this field if the company doesn't accept checks
    showChecksPayableField: computed('company.id', function () {
      return this.get('company.id') != 138;
    }),
    invoiceFrequencyUsers: function invoiceFrequencyUsers(invoiceFrequencies) {
      var _this2 = this;

      var checkIsOnFrequency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var exceptionCount = 0;
      Object.keys(this.get('model.invoiceFrequencyCounts')).forEach(function (frequency) {
        if (checkIsOnFrequency) {
          if (invoiceFrequencies.includes(frequency)) {
            exceptionCount += _this2.get('model.invoiceFrequencyCounts')[frequency];
          }
        } else {
          if (!invoiceFrequencies.includes(frequency)) {
            exceptionCount += _this2.get('model.invoiceFrequencyCounts')[frequency];
          }
        }
      });
      return exceptionCount;
    },
    salesTaxHelperText: 'Sales tax rates and laws vary from state to state. To collect sales tax, your state may require you to obtain a sales tax permit. Please check your state and/or city websites for more information on tax laws in your area.',
    removeSalesTaxPolicyText: computed('selectedToRemoveSalesTaxPolicy', 'activeSalesTaxPolicies.[]', function () {
      if (this.get('activeSalesTaxPolicies.length') === 1) {
        return "You're removing your ".concat(parseFloat(this.get('selectedToRemoveSalesTaxPolicy.rate')), "% tax rate (").concat(this.get('selectedToRemoveSalesTaxPolicy.name'), "). If you proceed, no sales tax would be applied on invoices going forward unless you add a new tax rate.");
      }

      var defaultPolicy = this.get('activeSalesTaxPolicies').find(function (policy) {
        return policy.get('is_default');
      });

      if (defaultPolicy === this.get('selectedToRemoveSalesTaxPolicy')) {
        var foundDefault = false;
        this.get('activeSalesTaxPolicies').forEach(function (policy) {
          if (policy !== defaultPolicy && !foundDefault) {
            defaultPolicy = policy;
            foundDefault = true;
          }
        });
      }

      return "You\u2019re removing your ".concat(parseFloat(this.get('selectedToRemoveSalesTaxPolicy.rate')), "% tax rate (").concat(this.get('selectedToRemoveSalesTaxPolicy.name'), "). Any families currently on this tax rate will be set to your default sales tax rate of ").concat(parseFloat(defaultPolicy.get('rate')), "% for future invoices.");
    }),
    activeSalesTaxPolicies: computed('company.sales_tax_policies.@each.isDeleted', function () {
      return this.get('company.sales_tax_policies').filter(function (policy) {
        return !policy.get('isDeleted');
      });
    }),
    actions: {
      selectEPaymentOption: function selectEPaymentOption(value) {
        if (value == 'none') {
          this.set('companyTaxId', null);
        }
      },
      updateAutoPayPreference: function updateAutoPayPreference(value) {
        this.set('company.auto_pay', value);
      },
      updateServiceFee: function updateServiceFee(value) {
        this.set('company.charge_service_fee', value);
      },
      openPaymentMethodSelector: function openPaymentMethodSelector() {
        this.set('showChooseBankAccountLinkingMethod', true);
      },
      closePaymentMethodSelector: function closePaymentMethodSelector() {
        this.set('showChooseBankAccountLinkingMethod', false);
      },
      confirmNeverAutoBill: function confirmNeverAutoBill() {
        this.set('showConfirmNeverAutoBill', false);
      },
      denyNeverAutoBill: function denyNeverAutoBill() {
        this.set('invoiceFrequency', 'monthly');
      },
      confirmSwitchUserInvoiceFrequencies: function confirmSwitchUserInvoiceFrequencies() {
        this.set('showConfirmSwitchUserInvoiceFrequencies', false);
        Ember.getOwner(this).lookup('route:companies.getting-paid').send('save', this.get('company'));
      },
      denySwitchUserInvoiceFrequencies: function denySwitchUserInvoiceFrequencies() {
        this.set('showConfirmSwitchUserInvoiceFrequencies', false);
      },
      bankAccountFocus: function bankAccountFocus() {
        this.set('showBankAccount', true);
        this.set('editingBankAccount', true);
      },
      closeBankAccount: function closeBankAccount() {
        this.set('showBankAccount', this.get('company.bank_account_number'));
        this.set('editingBankAccount', false);
      },
      closeInvoiceFrequencyModal: function closeInvoiceFrequencyModal() {
        var _this3 = this;

        this.set('showChangeInvoiceFrequencyModal', false);

        if (!this.get('company.update_all_households') && this.get('removedInvoiceFrequencies.length') > 0) {
          var removedFrequencies = this.get('removedInvoiceFrequencies').filter(function (frequency) {
            return frequency != _this3.get('originalDefaultInvoiceFrequency.name');
          });
          var exceptionCount = this.invoiceFrequencyUsers(removedFrequencies, true);

          if (exceptionCount > 0) {
            exceptionCount = this.invoiceFrequencyUsers(this.get('removedInvoiceFrequencies'), true);
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showRemovedInvoiceFrequencyModal', true);
          } else {
            this.send('save');
          }
        } else {
          this.send('save');
        }
      },
      closeRemoveInvoiceFrequencyModal: function closeRemoveInvoiceFrequencyModal(shouldProceed) {
        var _this4 = this;

        this.set('showRemovedInvoiceFrequencyModal', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.get('company.invoice_frequencies').clear();
          this.get('originalInvoiceFrequencies').forEach(function (frequency) {
            _this4.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },
      closeRemovedAutoBillModal: function closeRemovedAutoBillModal(shouldProceed) {
        var _this5 = this;

        this.set('showRemovedAutoBillModal', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.set('company.default_invoice_frequency', this.get('originalDefaultInvoiceFrequency'));
          this.get('originalInvoiceFrequencies').forEach(function (frequency) {
            _this5.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },
      closeAddedAutoBillModal: function closeAddedAutoBillModal() {
        this.set('showAddedAutoBillModal', false);
        this.send('save');
      },
      closeChangedDefaultInvoiceFrequency: function closeChangedDefaultInvoiceFrequency(shouldProceed) {
        var _this6 = this;

        this.set('showChangeInvoiceFrequencyModalWithNoExceptions', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.set('company.default_invoice_frequency', this.get('originalDefaultInvoiceFrequency'));
          this.get('originalInvoiceFrequencies').forEach(function (frequency) {
            _this6.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },
      closeAutoBill: function closeAutoBill() {
        this.set('editingAutoBill', false);
      },
      openAutoBill: function openAutoBill() {
        this.set('editingAutoBill', true);
      },
      openBillReminders: function openBillReminders() {
        this.set('editingBillReminders', true);
      },
      closeBillReminders: function closeBillReminders() {
        this.set('editingBillReminders', false);
      },
      openBankAccount: function openBankAccount() {
        this.set('editingBankAccount', true);
      },
      closeChangeSalesTaxPolicyDefaultModal: function closeChangeSalesTaxPolicyDefaultModal() {
        this.set('needSalesTaxPolicyDefaultAnswer', false);
        this.set('showChangeSalesTaxPolicyDefaultModal', false);
        this.send('save');
      },
      setBankAccount: function setBankAccount(token, account_id, nickname) {
        var manual_bank_account = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        this.set('showChooseBankAccountLinkingMethod', false);
        this.set('company.bank_account_nickname', nickname);
        this.set('company.account_id', account_id);

        if (manual_bank_account) {
          this.set('company.manual_token', token);
          this.set('company.token', null);
          this.set('company.payment_method', 'manual_bank_account');
        } else {
          this.set('company.manual_token', null);
          this.set('company.token', token);
          this.set('company.payment_method', 'bank_account');
        }
      },
      clickedSave: function clickedSave() {
        this.set('didValidate', true);

        if (!this.get('canSave')) {
          return;
        }

        var originalStp = this.get('originalDefaultSalesTaxPolicy');
        var defaultStp = this.get('activeSalesTaxPolicies').find(function (policy) {
          return policy.get('is_default');
        });

        if ((originalStp === null || originalStp === void 0 ? void 0 : originalStp.id) != (defaultStp === null || defaultStp === void 0 ? void 0 : defaultStp.id) && this.get('activeSalesTaxPolicies.length') > 0 && !originalStp.get('isDeleted')) {
          this.set('needSalesTaxPolicyDefaultAnswer', true);
          this.set('showChangeSalesTaxPolicyDefaultModal', true);
        }

        var oldFrequency = this.get('originalDefaultInvoiceFrequency');
        var newFrequency = this.get('company.default_invoice_frequency');

        if (oldFrequency && !newFrequency) {
          this.set('showRemovedAutoBillModal', true);
          return;
        } else if (newFrequency && !oldFrequency) {
          this.set('showAddedAutoBillModal', true);
          return;
        }

        if (!oldFrequency && !newFrequency) {
          this.send('save');
          return;
        }

        var exceptionCount = this.invoiceFrequencyUsers([newFrequency.get('name'), oldFrequency.get('name')], false);

        if (oldFrequency != newFrequency && newFrequency && oldFrequency) {
          if (exceptionCount == 0) {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showChangeInvoiceFrequencyModalWithNoExceptions', true);
          } else {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showChangeInvoiceFrequencyModal', true);
          }
        } else if (this.get('removedInvoiceFrequencies.length') > 0) {
          exceptionCount = this.invoiceFrequencyUsers(this.get('removedInvoiceFrequencies'), true);

          if (exceptionCount > 0) {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showRemovedInvoiceFrequencyModal', true);
          } else {
            this.send('save');
          }
        } else {
          this.send('save');
        }
      },
      save: function save() {
        if (this.get('needSalesTaxPolicyDefaultAnswer')) {
          return;
        }

        this.set('company.last_four_ssn', this.get('bankUser.last_four_ssn'));
        this.set('company.birthday', this.get('bankUser.dob'));
        this.set('company.tax_id', this.get('companyTaxId'));

        if (!this.get('company.acceptsEpayments')) {
          this.set('company.manual_token', null);
          this.set('company.token', null);
          this.set('company.payment_method', null);
          this.set('company.bank_account_nickname', null);
          this.set('company.account_id', null);
        }

        Ember.getOwner(this).lookup('route:companies.getting-paid').send('save', this.get('company'));
      },
      changeTaxId: function changeTaxId() {
        var _this7 = this;

        if (!this.get('changedTaxId')) {
          var company_id = this.get('company.id');

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company_id, "/notify_support_for_tax_id_change"),
            method: 'POST',
            data: {
              company_id: this.get('company.id')
            }
          }).done(function (response) {
            _this7.set('changingTaxId', true);

            _this7.get('flashMessage').sendFlashMessage(response.message, 'Success');
          });

          this.set('changedTaxId', true);
        }
      },
      hoverBankAccount: function hoverBankAccount(hover) {
        this.set('hoverBankAccount', hover);
      },
      hideReminderPopout: function hideReminderPopout() {
        this.set('showingReminderPopout', false);
      },
      addSalesTaxPolicy: function addSalesTaxPolicy() {
        var salesTaxPolicyName = this.get('activeSalesTaxPolicies').length === 0 ? this.get('company.state') : null;
        var salesTaxPolicy = this.get('store').createRecord('sales-tax-policy', {
          name: salesTaxPolicyName,
          is_default: this.get('activeSalesTaxPolicies.length') === 0
        });
        this.get('company.sales_tax_policies').pushObject(salesTaxPolicy);
        this.set('selectedSalesTaxPolicy', salesTaxPolicy);
      },
      editSalesTaxPolicy: function editSalesTaxPolicy(salesTaxPolicy) {
        this.set('selectedSalesTaxPolicy', salesTaxPolicy);
      },
      closeSalesTaxPolicyModal: function closeSalesTaxPolicyModal() {
        this.get('selectedSalesTaxPolicy').rollbackAttributes();
        this.set('selectedSalesTaxPolicy', null);
      },
      saveSalesTaxPolicy: function saveSalesTaxPolicy() {
        var _this8 = this;

        if (this.get('selectedSalesTaxPolicy.is_default')) {
          this.get('activeSalesTaxPolicies').forEach(function (policy) {
            policy.set('is_default', policy === _this8.get('selectedSalesTaxPolicy'));
          });
        } else {
          var defaultPolicy = this.get('activeSalesTaxPolicies').find(function (policy) {
            return policy !== _this8.get('selectedToRemoveSalesTaxPolicy');
          });
          defaultPolicy.set('is_default', true);
        }

        this.set('selectedSalesTaxPolicy', null);
      },
      deleteSalesTaxPolicy: function deleteSalesTaxPolicy(salesTaxPolicy) {
        this.set('selectedToRemoveSalesTaxPolicy', salesTaxPolicy);
      },
      confirmRemoveSalesTaxPolicy: function confirmRemoveSalesTaxPolicy() {
        var _this9 = this;

        if (this.get('selectedToRemoveSalesTaxPolicy.is_default')) {
          var defaultPolicy = this.get('activeSalesTaxPolicies').find(function (policy) {
            return policy !== _this9.get('selectedToRemoveSalesTaxPolicy');
          });

          if (defaultPolicy) {
            defaultPolicy.set('is_default', true);
          }
        }

        this.get('selectedToRemoveSalesTaxPolicy').deleteRecord();
        this.set('selectedToRemoveSalesTaxPolicy', null);
      },
      denyRemoveSalesTaxPolicy: function denyRemoveSalesTaxPolicy() {
        this.set('selectedToRemoveSalesTaxPolicy', null);
      },
      setDefaultSalesTaxPolicy: function setDefaultSalesTaxPolicy(salesTaxPolicy) {
        this.get('activeSalesTaxPolicies').forEach(function (policy) {
          policy.set('is_default', policy === salesTaxPolicy);
        });
      },
      salesTaxValidation: function salesTaxValidation(percentage) {
        var regex = /^(?!0+(\.0+)?$)(\d+|\d*\.\d{1,3})$/;
        return regex.test(percentage);
      }
    }
  });

  _exports.default = _default;
});