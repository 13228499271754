define("frontend/controllers/portal/appointment-request/service-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['serviceCategoryId'],
    appointmentRequestData: inject.service(),
    currentUser: inject.service(),
    selectedPets: computed.alias('appointmentRequestData.selectedPets'),
    household: computed.alias('appointmentRequestData.household'),
    selectedOfferedServiceAddOns: computed.alias('appointmentRequestData.offeredServiceAddOns'),
    selectedAddOnIds: computed.mapBy('selectedOfferedServiceAddOns', 'offered_service.id'),
    serviceCategoriesWithServices: computed('model.serviceCategories.[]', 'model.offeredServices.[]', 'serviceCategoryId', 'currentUser.serviceCategoriesWithPendingAgreements.[]', function () {
      var _this = this;

      console.log('Computing service categories with services...');
      var offeredServices = this.get('model.offeredServices');
      var serviceCategoriesWithPendingAgreements = this.get('currentUser.serviceCategoriesWithPendingAgreements');
      var categories = this.get('model.serviceCategories').map(function (category) {
        category.set('hasUnsignedAgreements', (serviceCategoriesWithPendingAgreements === null || serviceCategoriesWithPendingAgreements === void 0 ? void 0 : serviceCategoriesWithPendingAgreements.includes(category.get('id'))) || false);
        category.set('expanded', false);

        if (_this.get('serviceCategoryId') == category.get('id') || _this.get('appointmentRequestData.offeredService.service_category.id') == category.get('id')) {
          category.set('expanded', true);
        }

        var services = offeredServices.filter(function (service) {
          return service.get('service_category_id') == category.get('id');
        });
        services = services.sortBy('position');
        Ember.set(category, 'services', services);
        return category;
      });
      return categories.filter(function (category) {
        return category.services.length > 0;
      });
    }),
    serviceCategoryOptions: computed('serviceCategoriesWithServices.[]', function () {
      return this.get('serviceCategoriesWithServices').map(function (category) {
        return {
          label: category.get('name'),
          value: category.get('name')
        };
      });
    }),
    actions: {
      showSignContractsModal: function showSignContractsModal(categoryID) {
        this.send('showSignContractsModalForSpecificCategory', categoryID);
      },
      selectNewServiceCategory: function selectNewServiceCategory(categoryName) {
        var selectedCategory = this.get('model.serviceCategories').findBy('name', categoryName);
        selectedCategory.set('expanded', true);
      },
      clickOfferedService: function clickOfferedService(offeredService) {
        var oldServiceCategory = this.get('appointmentRequestData.offeredService.service_category');

        if (offeredService != this.get('appointmentRequestData.offeredService')) {
          this.set('appointmentRequestData.offeredServiceAddOns', []);
        }

        this.set('appointmentRequestData.offeredService', offeredService);
        this.set('showNextButton', offeredService.get('service_category.hasAddOnServices'));

        if (oldServiceCategory && oldServiceCategory.get('id') != offeredService.get('service_category.id')) {
          this.get('appointmentRequestData').resetAppointments();
        }

        if (!this.get('showNextButton')) {
          this.send('nextPage');
        }
      },
      previousPage: function previousPage() {
        this.transitionToRoute('portal.appointment-request.pet-selection');
      },
      nextPage: function nextPage() {
        if (this.get('appointmentRequestData.appointments.length') > 0 && this.get('appointmentRequestData.appointments.firstObject.timeFrame') != null && this.get('appointmentRequestData.hasBeenOnSummary')) {
          this.transitionToRoute('portal.appointment-request.summary', {
            queryParams: {
              ids: null,
              requestId: null
            }
          });
        } else {
          this.transitionToRoute('portal.appointment-request.date-selection');
        }
      },
      clickOfferedServiceAddOn: function clickOfferedServiceAddOn(addOnService) {
        var selectedAddOns = this.get('selectedOfferedServiceAddOns');
        var selectedAddOnIds = this.get('selectedAddOnIds');

        if (selectedAddOnIds.includes(addOnService.get('id'))) {
          this.set('selectedOfferedServiceAddOns', selectedAddOns.filter(function (selectedAddOn) {
            return selectedAddOn.get('offered_service.id') != addOnService.get('id');
          }));
        } else {
          this.set('selectedAddOnService', addOnService);

          if (this.get('selectedPets.length') == 1) {
            this.send('createNewAddOnService', this.get('selectedPets'));
          } else {
            this.set('showPickPetsModal', true);
          }
        }
      },
      closePickPetsModal: function closePickPetsModal() {
        this.set('showPickPetsModal', false);
      },
      createNewAddOnService: function createNewAddOnService(pets) {
        var selectedAddOns = this.get('selectedOfferedServiceAddOns');
        var newAddOn = Ember.Object.create({
          offered_service: this.get('selectedAddOnService'),
          petIds: pets.mapBy('id'),
          petNames: pets.mapBy('name'),
          pets: pets
        });
        selectedAddOns.pushObject(newAddOn);
        this.set('showPickPetsModal', false);
      }
    }
  });

  _exports.default = _default;
});